import Ajv from "ajv";
import {Decimal} from 'decimal.js';

/**
 * 构建ajv对象.
 */
let ajv = new Ajv({
    allErrors: true
});

// 添加关键字
ajv.addKeyword("errorMessage");
ajv.addKeyword("errorMessageI18n");

/**
 * 添加自定义日期校验器.
 */
ajv.addFormat("myDate", {

    type: "string",
    validate: function(val) {

        return validateDate(val);
    }
});

ajv.addFormat("myDateTime", {

    type: "string",
    validate: function (val) {

        if (/^(\d{4}-\d{2}-\d{2})\s(\d{2}:\d{2}:\d{2})$/.test(val)) {

            let dateTimeArr = val.split(" ");
            let timeArr = dateTimeArr[1].split(":");
            let myDate = new Date();
            myDate.setHours(timeArr[0]);
            myDate.setMinutes(timeArr[1])
            myDate.setSeconds(timeArr[2]);
            return validateDate(dateTimeArr[0]) && myDate.getHours() === Number(timeArr[0]) && myDate.getMinutes() === Number(timeArr[1]) && myDate.getSeconds() === Number(timeArr[2]);
        } else {

            return false;
        }
    }
})

/**
 * 根据传入的schema检查dataForm数据是否符合规范.
 * @param {Object} schema 自定义的schemajson
 * @param {Object} dataForm 需要检查的数据
 * @return {Object} {pass: true/false, rejectItemList: [item1, item2]}
 */
const validate = function(schema, dataForm) {
    // 数据格式化
    formFormat(schema, dataForm);
    // 拷贝数据防止篡改
    let checkDataForm = JSON.parse(JSON.stringify(dataForm));
    let checkSchema = JSON.parse(JSON.stringify(schema));
    // multipleOf小数位检查
    floatLimitHandle(checkSchema, checkDataForm);
    // ajv对象初始化
    let ajvCom = ajv.compile(checkSchema);
    // 传入检查数据
    let valid = ajvCom(checkDataForm);
    // 定义返回值
    let value = {

        pass: true,
        // 检查未通过时，未通过的属性名
        rejectItemList: []
    }
    if (!valid) {

        // 检查未通过
        ajvCom.errors.forEach(item => {

            // 找到错误的属性名
            if (item.keyword === 'required') {

                value.rejectItemList.push(item.params.missingProperty);
            } else {

                value.rejectItemList.push(item.instancePath.slice(1));
            }
        })
        value.pass = false;
    }
    return value;
}

/**
 * 此方法专为单项参数检查，页面上blur的单个项目检查不需要单独写schema。
 * 根据传入的schema检查数据是否符合规范.
 * @param {Object} schema 自定义的schema json
 * @param {Object} dataForm 需要检查的数据
 * @return {Object} {true/false true-通过}
 */
const validateOne = function(schema, dataForm) {
    //数据格式化
    formFormat(schema, dataForm);
    // 拷贝数据防止篡改
    let checkDataForm = JSON.parse(JSON.stringify(dataForm));
    let checkSchema = JSON.parse(JSON.stringify(schema));
    // multipleOf小数位检查
    floatLimitHandle(checkSchema, checkDataForm);
    // ajv对象初始化
    let ajvCom = ajv.compile(checkSchema);
    // 传入检查数据
    let valid = ajvCom(checkDataForm);
    // 定义返回值
    let pass = true; //true-通过，false-不通过
    if (!valid) {

        // 检查未通过
        ajvCom.errors.forEach(item => {
            // 找到错误的属性名
            let key;
            if (item.keyword === 'required') {

                key = item.params.missingProperty;
            } else {

                key = item.instancePath.slice(1);
            }
            for (let dataKey in dataForm) {

                if (key === dataKey) {

                    pass = false;
                }
            }
        })
    }
    return pass;
}

/**
 * 表单格式化.
 * @param {Object} form 数据
 */
const formFormat = function(schema, form) {

    for (let key in schema.properties) {
        // 如果有校验规则里是string类型则转成string
        let type = schema.properties[key].type;
        if (form[key]) {

            if (type === 'string') {

                form[key] = String(form[key]);
            } else if (type === 'number' || type === 'integer') {
                // 纯数转为数字
                let rule = /^(\-|\+)?\d+(\.\d+)?$/
                if (rule.test(form[key])) {

                    form[key] = Number(form[key]);
                }
            }
        }
    }
}

/**
 * 判断小数位数是通过配置multipleOf，例如能和0.01整除则一定是2位小数以内，
 * 但前段校验时小数整除有问题，因此将配置的multipleOf和待提交数据都变成整数.
 * @param {Object} schema jsonSchema配置
 * @param {Object} dataForm 检验数据
 */
const floatLimitHandle = function(schema, dataForm) {

    for (let key in schema.properties) {
        // 查看配置中是否有整除限制
        let multipleOf = schema.properties[key].multipleOf;
        if (multipleOf) {

            multipleOf = Number(multipleOf);
            // multipleOf不可能小于等于0
            if (String(multipleOf).indexOf('.') > -1) {
                // 小数点的位置
                let x = String(multipleOf).indexOf('.') + 1;
                // 小数的位数
                let num = String(multipleOf).length - x;
                // multipleOf改为1
                schema.properties[key].multipleOf = 1;
                // 检查值需要对应乘10的num次方
                let mulParam = new Decimal(Math.pow(10, num));
                if (dataForm[key]) {

                    dataForm[key] = new Decimal(Number(dataForm[key])).mul(mulParam).toNumber();
                }
                // 配置文件中的最大最小值也需要对应放大
                if (schema.properties[key].minimum) {

                    let minimum = Number(schema.properties[key].minimum);
                    schema.properties[key].minimum = new Decimal(minimum).mul(mulParam).toNumber();
                }
                if (schema.properties[key].maximum) {

                    let maximum = Number(schema.properties[key].maximum);
                    schema.properties[key].maximum = new Decimal(maximum).mul(mulParam).toNumber();
                }
            }
        }
    }
}

/**
 * 校验日期.
 * @param val 日期 必须符合 yyyy-MM-dd格式的字符串
 * @returns {boolean} true 校验通过 false 校验不通过
 */
function validateDate(val) {

    if (/^\d{4}-\d{2}-\d{2}$/.test(val)) {

        let dateArr = val.split("-");
        let myDate = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
        return myDate.getFullYear() === Number(dateArr[0]) && myDate.getMonth() === Number(dateArr[1] - 1) && myDate.getDate() === Number(dateArr[2]);
    } else {

        return false;
    }
}

export default {

    validate,
    validateOne
}
