import axios from 'axios';
import global from '../../components/Global.vue';

let esaioBase = global.esaioServerBase;

// 根据接口路径参数请求json schema
export const queryJsonSchemaByInterfaceUrl = params => { return axios.post(esaioBase + '/jsonSchema/getJsonSchemaDetail', params)};
// 查询Modbus写指令json schema详情
export const queryModbusCommandJsonSchemaDetail = params => { return axios.post(esaioBase + '/jsonSchema/queryModbusCommandJsonSchemaDetail', params)};
// 查询esaio-manager的json schema
export const queryEsaioManagerJsonSchemaDetail = params => { return axios.post(esaioBase + '/jsonSchema/queryDetailByDeviceSnAndCommandCode', params)};
